.container.converter {
    max-width: 600px;
}

.form-control {
    border-radius: 25px;
    color: #2e2833;
    height: 50px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
}

.blue {
    background-color: #ccdcee;
    border-color: #ccdcee;
}

.green, .form-control[readonly].green {
    background-color: #bcd7db;
    border-color: #bcd7db;
}


select.custom {
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../img/arrow.png");
    background-position: calc(100% - 20px) calc(1em + 4px);
    background-size: 18px 10px;
    background-repeat: no-repeat;
    text-transform: uppercase;
}

select.custom:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
