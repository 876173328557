@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #000000;
  min-height: 100%;
  font-weight: 300;
}

b {
  font-weight: 700;
}

.main-wrapper {
  margin: 30px 0;
}

.form-control.blue:focus {
  border-color: #ccdcee;
  background-color: #ccdcee;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
}

.form-control.green:focus {
  border-color: #bcd7db;
  background-color: #bcd7db;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
}

.btn {
  background-color: #ffc00f;
  border-color: #ffc00f;
  text-transform: uppercase;
  line-height: 36px;
}

.btn img{
  margin-top: -5px;
  margin-right: 5px;
  display: inline-block;
}

.btn:focus,.btn:active {
  outline: none !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.17);
  background-color: #ffc00f;
  border-color: #ffc00f;
}

