hr{
    border: 0;
    width: 100%;
    height: 3px;
    background: #ffc00f;
    margin: 30px 0;
}

.location, .today {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.today {
    font-size: 16px;
    margin-bottom: 15px;
}

.wi {
    display: block;
    font-family: 'weathericons';
    color: #323D54;
    font-size: 5rem;
    line-height: 8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon{
    text-align: center;
}

.temperature {
    margin: 0 0 20px 0;
    font-size: 3rem;
    line-height: 2rem;
    height: 100%;
    position: relative;
    font-weight: 300;
}

.temperature-feels {
    display: block;
    font-size: 17px;
    font-weight: 300;
    line-height: 0;
    margin-top: 30px;
    line-height: normal;
}

.weather-desc {
    display: inline-block;
    padding: 5px 10px;
    background: rgba(0,0,0,0.1);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    font-size: 16px;
}

.weather-params {
    list-style: none;
    margin: 40px 0 0 0;
    padding: 0;
}
.weather-params > li {
    overflow: hidden;
    font-weight: 300;
    display: inline-block;
    margin: 5px 5px;
}

.weather-params > li span {
    position: relative;
    font-weight: 500;
}

.inner {
    border-right: 1px solid rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
}

.inner .wi {
    font-size: 40px;
    line-height: 70px;
}

.scrolling-wrapper{
    overflow-x: auto;
}

.scrolling-wrapper div:first-child .inner{
    border-left: 1px solid rgba(0,0,0,0.1);
}

.m15{
    margin-top: 15px;
    margin-bottom: 15px;
}

.alert{
    font-size: 14px;
}

.right-arrow{
    margin-top: 12px;
}

.card-header{
    cursor: pointer;
}

.card-header .wi{
    width: 30px;
    font-size: 30px;
    line-height: 46px;
}

.title-data{
    margin-left: 30px;
    font-weight: 500;
}

.card-body{
    padding-left: 100px;
}

.card-body .weather-params{
    margin-top: 0;
}

.card-body p{
    font-weight: 400;
}

.form-wrapper {
    margin: 20px 0;
}

.btn-primary {
    background: #ffc00f;
    border-color: #ffc00f;
}

.search{
    padding-left: 25px;
    padding-right: 25px;
    max-width: 70px;
}

.search-form{
    margin-top: -116px;
}

.form-control-weather{
    height: 25px;
}

.current-data{
    min-height: 200px;
}
